import React, { useEffect, useState } from "react"

import { useTranslation } from "@bounce/i18n"
import { useAsync } from "@bounce/util"
import type { NavbarBrandProps } from "@bounce/web-components"
import { NavbarBrand, Navbar as BaseNavbar, NavbarBurgerButton } from "@bounce/web-components"

import { NavbarDesktop } from "./NavbarDesktop"
import { NavbarMobile } from "./NavbarMobile"
import type { MobileAndDesktopProps } from "./types"
import type { Product } from "@/types"
import { getUser } from "@/utils/auth"

export type NavbarProps = {
  product: Product
  Logo: NavbarBrandProps["Logo"]
} & Omit<MobileAndDesktopProps, "Logo">

export const Navbar = ({ alternates, Logo, links, languages, product, className, onToggleMenu }: NavbarProps) => {
  const { t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)
  const { data: user } = useAsync(getUser)
  const authenticated = !!user

  const onClickBurger = () => setShowMenu(prev => !prev)

  useEffect(() => {
    onToggleMenu?.(showMenu)
  }, [showMenu, onToggleMenu])

  return (
    <BaseNavbar className={className}>
      <NavbarBrand
        Logo={Logo}
        title="Bounce"
        className={showMenu ? "text-white" : "text-primary"}
        {...links.homepage}
      />
      <NavbarDesktop
        links={links}
        alternates={alternates}
        authenticated={authenticated}
        languages={languages}
        product={product}
        className="hidden xl:flex"
      />
      {showMenu && (
        <NavbarMobile links={links} authenticated={authenticated} languages={languages} className="flex xl:hidden" />
      )}
      <NavbarBurgerButton
        label={t("cmp.navBar.menuButtonLabel", "Menu")}
        open={showMenu}
        onClick={onClickBurger}
        className="flex xl:hidden"
      />
    </BaseNavbar>
  )
}
